<template>
  <div>
    <v-dialog
      v-model="show"
      max-width="90vh"
      transition="dialog-top-transition"
      @click:outside="closeUpdateContactModal(false)"
    >
      <!-- @click:outside="closeModalUpdateContact()"⁄ -->
      <v-card rounded="lg">
        <v-card-title primary-title class="justify-center pa-10">
          <div>
            <h2 class="black--text">
              Asesor
            </h2>
          </div>
        </v-card-title>
        <v-card-text>
          <!-- {{ allLocation }} -->
          <v-form ref="updateContactForm" v-model="updateContactFormValid">
            <v-row justify="space-between" align="center">
              <v-col cols="6" sm="6">
                <div class="mb-3">
                  <h3>
                    Nombre
                  </h3>
                </div>
                <v-text-field
                  v-model="name"
                  :rules="nameRules"
                  :disabled="isLoadingUpdateContact"
                  outlined
                  placeholder="Nombre"
                  required
                />
              </v-col>
              <v-col cols="6" sm="6">
                <div class="mb-3">
                  <h3>
                    Usuario
                  </h3>
                </div>
                <v-text-field
                  v-model="userName"
                  disabled
                  filled
                  placeholder="Usuario"
                  required
                />
              </v-col>
            </v-row>
            <v-row justify="space-between" align="center">
              <v-col cols="12" sm="6">
                <div class="mb-3">
                  <h3>
                    Correo
                  </h3>
                </div>
                <v-text-field
                  v-model="email"
                  :rules="emailRules"
                  disabled
                  filled
                  placeholder="Correo"
                  required
                />
              </v-col>
              <v-col cols="12" sm="6">
                <div class="mb-3">
                  <h3>
                    Teléfono
                  </h3>
                </div>
                <v-text-field
                  v-model="phone"
                  :disabled="isLoadingUpdateContact"
                  outlined
                  placeholder="Teléfono"
                  required
                />
              </v-col>
            </v-row>
          </v-form>
          <v-card class="mx-auto" elevation="0">
            <v-toolbar flat color="transparent">
              <v-toolbar-title>Proyecto Asignados</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-col v-if="!allSelected" cols="6">
                <v-text-field
                  ref="search"
                  v-model="search"
                  :disabled="isLoadingUpdateContact"
                  full-width
                  hide-details
                  label="Filtrar proyecto"
                  single-line
                ></v-text-field>
              </v-col>
            </v-toolbar>
            <v-container class="py-0">
              <v-row align="center" justify="start">
                <v-col v-if="selections.length <= 0" class="pa-10 grey--text">
                  Este Asesor no pertence a ningun proyecto
                </v-col>
                <v-col
                  v-else
                  v-for="(selection, i) in selections"
                  :key="i"
                  class="shrink"
                >
                  <v-chip
                    :disabled="isLoadingUpdateContact"
                    close
                    @click:close="
                      selectedRealEstate.splice(i, 1), selections.splice(i, 1)
                    "
                  >
                    {{ selection.name }}
                  </v-chip>
                </v-col>
              </v-row>
            </v-container>

            <v-divider v-if="!allSelected"></v-divider>
            <div v-if="!allSelected" class="py-5 grey--text">
              Proyectos disponibles para Asignar
            </div>
            <v-list>
              <template v-for="item in availableRealEstate">
                <v-list-item
                  v-if="!selectedRealEstate.includes(item)"
                  :key="item._id"
                  :disabled="isLoadingUpdateContact"
                  @click="selectedRealEstate.push(item), selections.push(item)"
                >
                  <v-list-item-avatar>
                    <v-icon :disabled="isLoadingUpdateContact">
                      mdi-plus</v-icon
                    >
                  </v-list-item-avatar>
                  <v-list-item-title v-text="item.name"></v-list-item-title>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
          <small class="red--text">{{ messageError }}</small>
        </v-card-text>
        <v-card-actions>
          <v-col cols="12" sm="6">
            <v-btn
              class="mb-15 rounded-lg"
              color="primary"
              text
              :disabled="isLoadingUpdateContact"
              block
              large
              @click="closeUpdateContactModal(false)"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              class="mb-15 rounded-lg"
              color="primary"
              :disabled="isLoadingUpdateContact"
              :loading="isLoadingUpdateContact"
              block
              large
              @click="validateUpdateContactForm"
            >
              Actualizar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      updateContactFormValid: true,
      isLoadingUpdateContact: false,
      updatedData: null,
      messageError: "",
      statusUpdate: "",
      contactToUpdate: null,
      role: "",
      name: "",
      nameRules: [v => !!v || "El nombre es requerido"],
      userName: "",
      userNameRules: [v => !!v || "El usuario es requerido"],
      phone: "",
      phoneRules: [
        v => !!v || "El teléfono es requerido",
        // v => v.length >= 10 || "The phone number is incorrect",
        // v => v.length <= 10 || "The phone number is incorrect",
        v =>
          Number.isInteger(Number(v)) || "El teléfono no puede contener letras"
      ],
      email: "",
      emailRules: [
        v => !!v || "E-mail es requerido",
        v => /.+@.+/.test(v) || "E-mail no valido"
      ],
      search: "",
      allLocation: [],
      realEstateAssingnedId: [],
      selectedRealEstate: [],
      allSelected: [],
      availableRealEstate: [],
      selections: [],
      realEstate: [],
      showTooltip: false
    };
  },
  computed: {
    ...mapState({
      contactListSt: state => state.contacts.contactList
    }),
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close", this.updatedData);
        }
      }
    }
  },
  watch: {
    selectedRealEstate() {
      this.search = "";
    },
    search(newValue) {
      if (newValue) {
        this.availableRealEstate = this.realEstate;
        let list = this.availableRealEstate.filter(l => {
          return l.name.toLowerCase().includes(newValue.toLowerCase());
        });
        this.availableRealEstate = list;
      } else {
        this.availableRealEstate = this.realEstate;
      }
    }
  },
  methods: {
    ...mapActions({
      updateContactByIdStore: "contacts/updateContactById",
      updateContactAllocationStore: "contacts/updateContactListAllocation",
      postCreateContactListLocationStore:
        "contacts/postCreateContactListLocation"
    }),
    ...mapMutations({
      setContactStore: "contacts/setContact"
    }),
    setAllLocation(list) {
      this.allLocation = list;
    },
    setRealEstate(list) {
      this.selections = [];
      this.selectedRealEstate = [];
      this.realEstate = [];
      this.allSelected = false;
      this.realEstate = list;
      for (let i = 0; i < this.realEstateAssingnedId.length; i++) {
        for (let x = 0; x < this.realEstate.length; x++) {
          if (this.realEstate[x]._id === this.realEstateAssingnedId[i]) {
            this.selectedRealEstate.push(this.realEstate[x]);
          }
        }
      }

      this.availableRealEstate = this.realEstate;
      this.allSelected =
        this.selectedRealEstate.length === this.realEstate.length;
      for (let i = 0; i < this.selectedRealEstate.length; i++) {
        this.selections.push(this.selectedRealEstate[i]);
      }
    },
    setContactUpdate(newValue) {
      if (newValue) {
        this.contactToUpdate = newValue;
        this.name = newValue.name ? newValue.name : "";
        this.email = newValue.email ? newValue.email : "";
        this.phone = newValue.phone ? newValue.phone : "";
        this.role = newValue.role ? newValue.role : "";
        this.realEstateAssingnedId = newValue.real_estate_develop_id
          ? newValue.real_estate_develop_id
          : [];
      } else {
        this.contactToUpdate = null;
        this.name = "";
        this.email = "";
        this.phone = "";
        this.role = "";
        this.realEstateAssingnedId = [];
        this.selectedRealEstate = [];
      }
    },
    closeUpdateContactModal(val) {
      this.messageError = "";
      this.show = val;
      this.$refs.updateContactForm.resetValidation();
    },
    validateUpdateContactForm() {
      if (this.$refs.updateContactForm.validate()) {
        // if (this.selectedRealEstate.length === 0) {
        //   this.messageError = "No puedes dejar un asesor sin desarollo.";
        // } else {
        this.createProject().then(() => {
          this.userProjectsIn().then(() => {
            this.updateContact();
          });
        });
        // }
      } else {
        this.messageError = "Por favor, verifica los errores.";
      }
    },
    async createProject() {
      const self = this;
      this.isLoadingUpdateContact = true;
      for (let x = 0; x < this.selectedRealEstate.length; x++) {
        if (
          this.allLocation.filter(i => {
            if (
              i &&
              i.real_estate_develop_id !== undefined &&
              i.real_estate_develop_id !== ""
            ) {
              return (
                i.real_estate_develop_id === this.selectedRealEstate[x]._id
              );
            } else {
              return false;
            }
          }).length === 0
        ) {
          await this.postCreateContactListLocationStore({
            name: this.selectedRealEstate[x].name,
            realEstateDevelopId: this.selectedRealEstate[x]._id,
            contact_list: []
          }).then(res => {
            self.allLocation = res
              ? res.response.data.Allocation
              : self.allLocation;
          });
        }
      }
    },
    async userProjectsIn() {
      this.isLoadingUpdateContact = true;

      let tmpRealEstateAllId = [];
      for (let x = 0; x < this.realEstate.length; x++) {
        tmpRealEstateAllId.push(this.realEstate[x]._id);
      }

      let tmpRealEstateAssingSelected = [];
      for (let i = 0; i < this.selectedRealEstate.length; i++) {
        tmpRealEstateAssingSelected.push(this.selectedRealEstate[i]._id);
      }

      let tmpRealEstateAssingNoSelected = [];
      for (let i = 0; i < tmpRealEstateAllId.length; i++) {
        if (!tmpRealEstateAssingSelected.includes(tmpRealEstateAllId[i])) {
          tmpRealEstateAssingNoSelected.push(tmpRealEstateAllId[i]);
        }
      }

      let noSelected = [];
      let selected = [];

      for (let s = 0; s < this.realEstate.length; s++) {
        for (let n = 0; n < tmpRealEstateAssingSelected.length; n++) {
          if (this.realEstate[s]._id === tmpRealEstateAssingSelected[n]) {
            selected.push(this.realEstate[s]);
          }
        }
      }

      for (let s = 0; s < this.realEstate.length; s++) {
        for (let n = 0; n < tmpRealEstateAssingNoSelected.length; n++) {
          if (this.realEstate[s]._id === tmpRealEstateAssingNoSelected[n]) {
            noSelected.push(this.realEstate[s]);
          }
        }
      }

      for (let i = 0; i < selected.length; i++) {
        for (let j = 0; j < this.allLocation.length; j++) {
          if (
            this.allLocation[j] &&
            this.allLocation[j].real_estate_develop_id !== undefined &&
            this.allLocation[j].real_estate_develop_id === selected[i]._id
          ) {
            if (
              this.allLocation[j].contact_list.filter(i => {
                return i.idContact === this.contactToUpdate._id;
              }).length === 0
            ) {
              let tmpList = this.allLocation[j].contact_list;
              tmpList.push({
                idContact: this.contactToUpdate._id,
                status: this.contactToUpdate.status,
                status_assigned: "0"
              });
              await this.updateContactAllLocations(
                this.allLocation[j],
                tmpList
              );
            }
          }
        }
      }

      for (let i = 0; i < noSelected.length; i++) {
        for (let x = 0; x < this.allLocation.length; x++) {
          if (
            this.allLocation[x] &&
            this.allLocation[x].real_estate_develop_id !== undefined &&
            this.allLocation[x].real_estate_develop_id === noSelected[i]._id
          ) {
            if (
              this.allLocation[x].contact_list.filter(i => {
                return i.idContact === this.contactToUpdate._id;
              }).length > 0
            ) {
              await this.updateContactAllLocations(
                this.allLocation[x],
                this.allLocation[x].contact_list.filter(i => {
                  return i.idContact !== this.contactToUpdate._id;
                })
              );
            }
          }
        }
      }
    },
    async updateContactAllLocations(allLocation, contactList) {
      await this.updateContactAllocationStore({
        idAllocation: allLocation._id,
        real_estate_group_id: allLocation.real_estate_group_id,
        name: allLocation.name,
        contact_list: contactList
      });
    },
    updateContact() {
      let finalRealEstateAssing = [];
      for (let i = 0; i < this.selectedRealEstate.length; i++) {
        finalRealEstateAssing.push(this.selectedRealEstate[i]._id);
      }

      try {
        this.updateContactByIdStore({
          role: "admin",
          data: {
            _id: this.contactToUpdate._id,
            table: this.role,
            role: "admin",
            name: this.name,
            email: this.email,
            phone: this.phone,
            real_estate_develop_id: finalRealEstateAssing
          }
        }).then(res => {
          if (
            res &&
            res.user.contact_id != undefined &&
            res.user.contact_id != ""
          ) {
            this.statusUpdate = "Success";
            this.isLoadingUpdateContact = false;
            this.updatedData = res;
            this.closeUpdateContactModal(false);
          } else {
            this.updatedData = null;
            this.statusUpdate = "Error";
            this.isLoadingUpdateContact = false;
          }
        });
      } catch (error) {
        this.updatedData = null;
        this.statusUpdate = "Error";
        this.isLoadingUpdateContact = false;
      }
    }
  }
};
</script>

<style scoped>
.v-dialog {
  border-radius: 24px !important;
  margin: 24px;
  overflow-y: auto;
  pointer-events: auto;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%;
  z-index: inherit;
  box-shadow: none !important;
}
.v-expansion-panel {
  box-shadow: none;
}
.note-project-assing-text {
  font-size: 14px;
  color: grey;
}
.note-project-assing-text-btn {
  font-size: 14px;
  font-weight: normal;
  text-transform: lowercase;
  color: #4ea6da;
  padding-bottom: 1000px;
  text-decoration: underline;
}

.note-project-assing-text-list {
  font-size: 14px;
  font-weight: 400;
}
.note-project-assing-text-tooltip-title {
  font-size: 18px;
}
</style>
